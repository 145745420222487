import { ArrowForward } from "@mui/icons-material";
import { Button, Stack } from "@mui/joy";
import React from "react";
import "./App.css";

function App() {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Button
        size="lg"
        startDecorator={<ArrowForward />}
        component="a"
        href="https://app.syncpulseapp.com"
      >
        Open SyncPulse App
      </Button>
    </Stack>
  );
}
export default App;

//   return (
//     <Container>
//       {/* Hero Section */}
//       <Box
//         sx={{
//           backgroundImage: "url(/path/to/office-background.jpg)",
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           color: "white",
//           textAlign: "center",
//           padding: "100px 20px",
//           position: "relative",
//         }}
//       >
//         <Box
//           sx={{
//             position: "absolute",
//             top: 0,
//             left: 0,
//             right: 0,
//             bottom: 0,
//             backgroundColor: "rgba(0, 0, 0, 0.5)",
//           }}
//         />
//         <Typography variant="h2" component="h1">
//           Automate Your Workflow, Save Time, and Boost Efficiency
//         </Typography>
//         <Typography variant="h5" component="h2" sx={{ margin: "20px 0" }}>
//           SyncPulse automates file transfers, from banks to accounting software,
//           directly through your browser.
//         </Typography>
//         <Button variant="contained" color="primary" sx={{ margin: "20px 0" }}>
//           Get Started for Free
//         </Button>
//         <Typography variant="body1">
//           No more manual exports and imports. Automate your processes with our
//           Chrome Extension.
//         </Typography>
//       </Box>

//       {/* Features Section */}
//       <Grid container spacing={4} sx={{ marginTop: "40px" }}>
//         <Grid item xs={12} md={4}>
//           <Cloud fontSize="large" />
//           <Typography variant="h6">Seamless Integration</Typography>
//           <Typography variant="body1">
//             Easily connect and automate your workflow across banks, accounting
//             software, and more.
//           </Typography>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <AccessTime fontSize="large" />
//           <Typography variant="h6">Save Time and Resources</Typography>
//           <Typography variant="body1">
//             Say goodbye to repetitive manual tasks and focus on what matters.
//           </Typography>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <Security fontSize="large" />
//           <Typography variant="h6">AI at the Core</Typography>
//           <Typography variant="body1">
//             Our AI-driven tools ensure accuracy and efficiency with every file
//             transfer.
//           </Typography>
//         </Grid>
//       </Grid>

//       {/* How It Works Section */}
//       <Box sx={{ marginTop: "40px" }}>
//         <Typography variant="h4" component="h2" sx={{ textAlign: "center" }}>
//           How It Works
//         </Typography>
//         <Grid container spacing={4} sx={{ marginTop: "20px" }}>
//           <Grid item xs={12} md={3}>
//             <img
//               src="/path/to/extension-download.jpg"
//               alt="Download the Chrome Extension"
//             />
//             <Typography variant="body1">
//               1. Download the Chrome Extension
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <img
//               src="/path/to/record-workflow.jpg"
//               alt="Record Export and Import Workflows"
//             />
//             <Typography variant="body1">
//               2. Record Export and Import Workflows
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <img src="/path/to/one-click.jpg" alt="Automate in One Click" />
//             <Typography variant="body1">3. Automate in One Click</Typography>
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <img src="/path/to/dashboard.jpg" alt="Sync and Save Time" />
//             <Typography variant="body1">4. Sync and Save Time</Typography>
//           </Grid>
//         </Grid>
//       </Box>

//       {/* Testimonials Section */}
//       <Box sx={{ marginTop: "40px" }}>
//         <Typography variant="h4" component="h2" sx={{ textAlign: "center" }}>
//           Testimonials
//         </Typography>
//         <Grid container spacing={4} sx={{ marginTop: "20px" }}>
//           <Grid item xs={12} md={6}>
//             <img
//               src="/path/to/business-professional.jpg"
//               alt="Business professional"
//             />
//             <Typography variant="body1">
//               SyncPulse saved us 3.5 hours each month! It's a game-changer for
//               our team.
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <img src="/path/to/financial-analyst.jpg" alt="Financial analyst" />
//             <Typography variant="body1">
//               With SyncPulse, we've automated workflows we thought were
//               impossible.
//             </Typography>
//           </Grid>
//         </Grid>
//       </Box>

//       {/* Pricing Section */}
//       <Box sx={{ marginTop: "40px", textAlign: "center" }}>
//         <Typography variant="h4" component="h2">
//           Simple, Transparent Pricing
//         </Typography>
//         <Typography variant="h5" component="h3" sx={{ margin: "20px 0" }}>
//           €10/workflow/month
//         </Typography>
//         <Typography variant="body1">
//           One workflow equals one file transfer. Pay yearly and save.
//         </Typography>
//       </Box>

//       {/* Footer */}
//       <Box sx={{ marginTop: "40px", textAlign: "center" }}>
//         <Typography variant="h6" component="h2">
//           Any Questions?
//         </Typography>
//         <Typography variant="body1">
//           Contact Timothee Clement at{" "}
//           <a href="mailto:timothee@example.com">timothee@example.com</a> or
//           connect on{" "}
//           <a href="https://www.linkedin.com/in/timothee-clement">LinkedIn</a>.
//         </Typography>
//         {/* <Box sx={{ marginTop: "20px" }}>
//           <a href="https://www.linkedin.com">
//             <LinkedInIcon />
//           </a>
//           <a href="https://www.twitter.com">
//             <TwitterIcon />
//           </a>
//         </Box> */}
//       </Box>
//     </Container>
//   );
// }
